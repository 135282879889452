import { SportsResultData, SportsResultElement } from '@hubcms/domain-story-elements';
import { ElementDataMapFn } from './types';
import { extractElementFields } from '../element-renderer';

export const mapSportsResult: ElementDataMapFn<SportsResultElement> = data => {
  const { teamOne, teamOneScore, teamTwo, teamTwoScore } = extractElementFields<SportsResultData>(data);

  return {
    teamOne,
    teamOneScore,
    teamTwo,
    teamTwoScore,
  };
};
