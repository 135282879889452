import { ElementsFragment, isElementWithChildren } from '@hubcms/domain-cook';
import {
  ImageStoryElement,
  type GalleryData,
  type GalleryStoryElement,
  type StoryElementType,
} from '@hubcms/domain-story-elements';
import { isNonNull } from '@hubcms/utils-browser';

import { isValidStoryElement } from '../is-valid-story-element';
import { ElementDataMapFnWithOptions, RecursiveMapFn } from './types';

const VALID_GALLERY_ELEMENTS: StoryElementType[] = ['image'];

export const mapGalleryData: ElementDataMapFnWithOptions<'elements' | 'sectionParams' | 'recursiveMapFn', GalleryStoryElement> = (
  data,
  { elements, recursiveMapFn },
) => {
  const children = isElementWithChildren(data) ? data.children.map(childId => elements.find(({ id }) => id === childId)) : [];

  return {
    slides: mapChildElementsToSlides(children.filter(isNonNull), recursiveMapFn),
  };
};

function mapChildElementsToSlides(children: ElementsFragment[], recursiveMapFn: RecursiveMapFn): GalleryData['slides'] {
  return children
    .map(recursiveMapFn)
    .filter(isValidStoryElement<ImageStoryElement>(VALID_GALLERY_ELEMENTS))
    .map(({ id, data }) => ({
      id,
      ...data,
    }))
    .filter(image => !!image.url);
}
