import { ElementsFragment, isElementBooleanField, isElementNumberField, isElementTextField } from '@hubcms/domain-cook';

export function extractElementFields<T extends Record<string, string | boolean | number | undefined>>(
  element?: ElementsFragment,
): T {
  if (!element) {
    return {} as T;
  }
  return Object.fromEntries(
    element.fields.map(field => {
      if (isElementTextField(field)) {
        return [field.name, field.value];
      }
      if (isElementBooleanField(field)) {
        return [field.name, field.booleanValue];
      }
      if (isElementNumberField(field)) {
        return [field.name, field.numberValue];
      }
      return [];
    }),
  );
}
