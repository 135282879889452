import type { LiveBlogStoryElement } from '@hubcms/domain-story-elements';

import { getElementField } from '../get-element-field';

import { ElementDataMapFnWithOptions } from './types';

export const mapLiveBlogData: ElementDataMapFnWithOptions<'sectionParams', LiveBlogStoryElement> = (data, { sectionParams }) => {
  const tickarooThemeId = sectionParams['tickaroo.themeid'];
  const purposeIds = sectionParams['embed.gdpr.requiredconsents']?.split(',') ?? [];
  const liveblogId = getElementField<string>(data.fields, 'liveBlogId') ?? '';

  return {
    liveblogId,
    tickarooThemeId,
    purposeIds: purposeIds.map(purposeId => purposeId.trim()),
  };
};
