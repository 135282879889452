import { ElementsFragment, isElementWithChildren } from '@hubcms/domain-cook';
import { ListData, ListStoryElement, ParagraphData, StoryElement, StoryElementType } from '@hubcms/domain-story-elements';
import { isNonNull } from '@hubcms/utils-browser';

import { isValidStoryElement } from '../is-valid-story-element';
import { ElementDataMapFnWithOptions, RecursiveMapFn } from './types';

const NESTED_LIST_ITEM_TYPES: StoryElementType[] = [
  'infoblock_list_bulleted',
  'infoblock_list_numbered',
  'list_bulleted',
  'list_numbered',
];
const VALID_LIST_ELEMENTS: StoryElementType[] = [...NESTED_LIST_ITEM_TYPES, 'infoblock_paragraph', 'paragraph'];
type NestedListItemStoryElement =
  | StoryElement<'list_bulleted' | 'infoblock_list_bulleted', ListData<false>>
  | StoryElement<'list_numbered' | 'infoblock_list_numbered', ListData<true>>;
type ListItemStoryElement = NestedListItemStoryElement | StoryElement<'paragraph' | 'infoblock_paragraph', ParagraphData>;

type ListItem = ListData['items'][number];

export const mapList =
  (isOrdered: boolean): ElementDataMapFnWithOptions<'elements' | 'recursiveMapFn', ListStoryElement> =>
  (data, { elements, recursiveMapFn }) => {
    const children = isElementWithChildren(data) ? data.children.map(childId => elements.find(({ id }) => id === childId)) : [];

    const childStoryElements = mapChildElementsToListItems(children.filter(isNonNull), recursiveMapFn);

    if (!childStoryElements.length) {
      return null;
    }

    return {
      isOrdered,
      items: childStoryElements.map(mapListItem),
      charCount: childStoryElements.reduce((total, currentElement) => total + (currentElement.charCount ?? 0), 0),
    };
  };

function mapChildElementsToListItems(children: ElementsFragment[], recursiveMapFn: RecursiveMapFn): ListItemStoryElement[] {
  return children.map(recursiveMapFn).filter(isValidStoryElement<ListItemStoryElement>(VALID_LIST_ELEMENTS));
}

function isNestedListItem(storyElement?: ListItemStoryElement): storyElement is NestedListItemStoryElement {
  return !!storyElement && NESTED_LIST_ITEM_TYPES.includes(storyElement.type);
}

function mapListItem(storyElement: ListItemStoryElement): ListItem {
  if (isNestedListItem(storyElement)) {
    return {
      id: storyElement.id,
      type: 'list',
      props: storyElement.data,
    };
  }
  return {
    id: storyElement.id,
    type: 'paragraph',
    props: storyElement.data,
  };
}
