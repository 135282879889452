import { Newsletter } from '@hubcms/domain-newsletter';
import { error } from '@hubcms/utils-monitoring';

/**
 * TODO CW-2610: Remove obsolete validation when we no longer receive a string from the backend.
 *
 * Remove this function if needed. After both CW-2609 and CW-2610 are done we should be receiving an
 * Array of Newsletter objects and Typescript should cover most of the validation.
 */

export function parseSuggestions(suggestionString: string): Newsletter[] {
  let suggestions: unknown;

  try {
    suggestions = JSON.parse(suggestionString);
  } catch {
    error('Newsletter suggestions string does not contain valid JSON');
    return [];
  }

  if (!Array.isArray(suggestions)) {
    return [];
  }

  // TODO CW-2609: adjust mapping when the backend gives us new keys
  return suggestions
    .filter(suggestion => {
      if (typeof suggestion !== 'object') {
        return false;
      }

      if (suggestion === null) {
        return false;
      }

      return 'title' in suggestion && 'summary' in suggestion && 'id' in suggestion;
    })
    .map(suggestion => ({ id: suggestion.id, name: suggestion.title, description: suggestion.summary }));
}
