import { RATING_ICON_MAP, RatingElement } from '@hubcms/domain-story-elements';
import { ElementDataMapFn } from './types';
import { extractElementFields } from '../element-renderer';

export const mapRating: ElementDataMapFn<RatingElement> = data => {
  const { score, maximumScore, icon } = extractElementFields<{
    score: string;
    maximumScore: string;
    icon: keyof typeof RATING_ICON_MAP;
  }>(data);

  return {
    score: Number(score),
    maxScore: Number(maximumScore),
    icon,
  };
};
