import { AudioVisualArticle, ExtraPageData, IndexPageTagData, TextualArticle, isContextWithElements } from '@hubcms/domain-cook';

type TagIntroData = Exclude<IndexPageTagData['intro'], undefined>[number];
export type ArticleDataWithElements = TextualArticle['context'] | ExtraPageData['context'] | TagIntroData;
export type ArticleData = ArticleDataWithElements | AudioVisualArticle['context'];

export function isTagIntroData(articleData: ArticleData): articleData is TagIntroData {
  return !!articleData && (articleData.__typename === 'TagIntro' || articleData.__typename === 'TagIntroB2B');
}

export function isArticleDataWithElements(articleData: ArticleData | null): articleData is ArticleDataWithElements {
  if (articleData === null) {
    return false;
  }
  return isTagIntroData(articleData) || isContextWithElements(articleData);
}
