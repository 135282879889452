import { ElementsFragment } from '@hubcms/domain-cook';

import { extractElementFields } from './element-renderer/extractElementFields';

export type GetCaptionDataType = {
  caption: string | undefined;
  credit: string | undefined;
};

export const getCaptionData = (
  data?: ElementsFragment,
  prefix: string | null = null,
  captionFallBacks: string[] = [],
  creditFallBacks: string[] = [],
): GetCaptionDataType => {
  const captionData = extractElementFields<GetCaptionDataType>(data);
  let { caption, credit } = captionData;

  if (!caption) {
    caption = captionFallBacks.find(Boolean);
  }

  if (!credit) {
    credit = creditFallBacks.find(Boolean);
  }

  if (prefix && credit) {
    credit = `${prefix} ${credit}`;
  }

  return { caption, credit };
};
