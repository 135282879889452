import { HeadingData, HeadingStoryElement } from '@hubcms/domain-story-elements';
import { infoblockHeadingSize } from '@hubcms/brand';

import { getElementField } from '../get-element-field';

import { ElementDataMapFn } from './types';

const sizeMap = new Map<string, HeadingData['size']>([
  ['Sm', 'sm'],
  ['Md', 'md'],
  ['Lg', 'lg'],
]);

export const mapHeading =
  (context: HeadingData['context'], isSubheading?: boolean): ElementDataMapFn<HeadingStoryElement> =>
  data => {
    const text = getElementField<string>(data.fields, getFieldName(context, isSubheading)) ?? '';
    const listicleField = getElementField<string>(data.fields, 'listicle') ?? null;
    const sizeField = getElementField<string>(data.fields, 'headingSizes') ?? null;

    return {
      id: data.id,
      text,
      level: getLevel(context, isSubheading),
      size: getSize(context, isSubheading, sizeField),
      context,
      isSubheading,
      listicle: getListicle(isSubheading, listicleField),
      charCount: text.length,
    };
  };

function getLevel(context: HeadingData['context'], isSubheading?: boolean) {
  if (context === 'article') {
    return isSubheading ? 2 : 1;
  }
  if (isSubheading) {
    return 6;
  }
  return 5;
}

function getFieldName(context: HeadingData['context'], isSubheading?: boolean) {
  if (isSubheading) {
    return 'subhead';
  }
  if (context === 'infoblock') {
    return 'infoblockHeadline';
  }
  return 'headline';
}

function getSize(
  context: HeadingData['context'],
  isSubheading: boolean | undefined,
  sizeField: string | null,
): HeadingData['size'] {
  if (context === 'infoblock') {
    return isSubheading ? infoblockHeadingSize.subHeading : infoblockHeadingSize.heading;
  }

  if (!isSubheading) {
    return '2xl';
  }

  return mapSizeFieldToSize(sizeField);
}

function mapSizeFieldToSize(sizeField: string | null): HeadingData['size'] {
  if (sizeField) {
    return sizeMap.get(sizeField) || 'md';
  }

  return 'md';
}

function getListicle(isSubheading: boolean | undefined, listicleField: string | null): HeadingData['listicle'] {
  if (!isSubheading) {
    return null;
  }
  switch (listicleField) {
    case 'reset':
    case 'continue':
      return listicleField;
    default:
      return null;
  }
}
