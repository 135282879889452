import { ElementsFragment } from '@hubcms/domain-cook';

import { StoryElement, isStoryElementType } from '@hubcms/domain-story-elements';

import { ELEMENT_DATA_MAP } from './element-data-map';
import { ElementDataMapOptions, RecursiveMapFn } from './types';

export function mapStoryElementData(element: ElementsFragment, options: ElementDataMapOptions): StoryElement | null {
  if (isStoryElementType(element.type)) {
    const elementMapper = ELEMENT_DATA_MAP[element.type];
    const recursiveMapFn: RecursiveMapFn = data => mapStoryElementData(data, options);

    const mappedElement = elementMapper(element, {
      ...options,
      recursiveMapFn,
    });

    if (!mappedElement) {
      return null;
    }

    const { charCount, type, ...data } = mappedElement;

    return {
      id: element.id,
      type: type ?? element.type,
      charCount,
      data,
    };
  }
  return null;
}
