import { AnnotationLinkProps, EnrichedAnnotation } from '@hubcms/domain-story-elements';
import { Annotation } from '@hubcms/domain-cook';

import { AnnotationLinkElement } from '../../domain/AnnotationLinkElement';
import { extractElementFields } from '../element-renderer';
import { enrichAnnotation } from './enrichAnnotation';
import { getLineBreakAnnotations } from './getLineBreakAnnotations';

export function prepareAnnotations(
  text: string,
  annotations: Annotation[],
  linkElements: AnnotationLinkElement[],
): EnrichedAnnotation[] {
  const lineBreakAnnotations = getLineBreakAnnotations(text);

  const enrichedAnnotations = annotations.map(mapAnnotation(linkElements)).filter(isValidAnnotation).concat(lineBreakAnnotations);

  return enrichedAnnotations;
}

function mapAnnotation(linkElements: AnnotationLinkElement[]) {
  return (annotation: Annotation): EnrichedAnnotation | null => {
    const enrichedAnnotation = enrichAnnotation(annotation);

    if (['internal_link', 'inline_link'].includes(annotation.name)) {
      const annotationLinkProps = findAnnotationLinkProps(annotation.value, linkElements);
      if (!annotationLinkProps) {
        return null;
      }
      return {
        ...enrichedAnnotation,
        linkProps: annotationLinkProps,
      };
    }
    return enrichedAnnotation;
  };
}

function findAnnotationLinkProps(
  elementId: string | undefined,
  linkElements: AnnotationLinkElement[],
): AnnotationLinkProps | null {
  const element = linkElements.find(({ id }) => id === elementId);
  if (!element) {
    return null;
  }
  const fields = extractElementFields<{ uri: string; noFollow: boolean; newWindow: boolean }>(element);
  if (element.type === 'external_link') {
    return {
      uri: fields.uri,
      noFollow: fields.noFollow,
      target: fields.newWindow ? '_blank' : '_self',
    };
  }
  if (element.type === 'internal_link' && element.relation?.href) {
    return {
      uri: element.relation.href,
      noFollow: fields.noFollow,
      target: fields.newWindow ? '_blank' : '_self',
    };
  }
  return null;
}

function isValidAnnotation(annotation?: EnrichedAnnotation | null): annotation is EnrichedAnnotation {
  return !!annotation;
}
