import { RelatedArticleData, RelationStoryElement } from '@hubcms/domain-story-elements';
import { LinkableArticleRelationItem, OnHtmlBlock, isElementWithRelation } from '@hubcms/domain-cook';
import { ElementDataMapFnWithOptions } from './types';

const PREFIX_FALLBACK = 'ALSO READ.';

export const mapRelation: ElementDataMapFnWithOptions<'sectionParams', RelationStoryElement> = (data, { sectionParams }) => {
  const prefix = sectionParams['article.related.readmore.text'] || PREFIX_FALLBACK;

  if (isElementWithRelation<OnHtmlBlock>(data) && data.relation?.__typename === 'HtmlBlock') {
    return {
      type: 'code_block',
      htmlBlock: data.relation?.htmlBlock || '',
    };
  }

  if (!isElementWithRelation<LinkableArticleRelationItem>(data)) {
    return null;
  }

  const title = data.relation?.title;
  const url = data.relation?.href;
  const id = data.relation?.id;

  return createRelatedArticleData({
    prefix,
    title,
    url,
    id,
  });
};

function createRelatedArticleData(partialRelatedArticleData: Partial<RelatedArticleData> = {}): RelatedArticleData {
  return {
    prefix: '',
    title: '',
    url: '',
    id: '',
    ...partialRelatedArticleData,
  };
}
