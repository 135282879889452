import { PullQuoteStoryElement } from '@hubcms/domain-story-elements';
import { ElementDataMapFn } from './types';
import { extractElementFields } from '../element-renderer';

export const mapPullQuote: ElementDataMapFn<PullQuoteStoryElement> = data => {
  const { quote, quotee, quoteeFunction } = extractElementFields<{ quote: string; quotee?: string; quoteeFunction?: string }>(
    data,
  );

  return {
    quote,
    quotee,
    quoteeFunction,
  };
};
