import { MapsEmbedStoryElement, createMapsEmbedData } from '@hubcms/domain-story-elements';
import { warn } from '@hubcms/utils-monitoring';
import { ElementDataMapFnWithOptions } from './types';
import { getElementField } from '../get-element-field';

export const mapMapsEmbed: ElementDataMapFnWithOptions<'elements' | 'sectionParams', MapsEmbedStoryElement> = (
  data,
  { elements, sectionParams },
) => {
  const mapsData = getElementField<string>(data.fields, 'mapeditor') ?? '';
  const isFirstMapOfPage = elements.find(element => element.type === 'map')?.id === data.id;

  try {
    const { latitude, longitude, zoom, mapTypeId } = JSON.parse(mapsData);

    return createMapsEmbedData({
      isValid: true,
      longitude,
      latitude,
      zoom,
      mapTypeId,
      apiKey: sectionParams['google.maps.embed.api'],
      hasScriptTag: isFirstMapOfPage,
    });
  } catch (e) {
    if (typeof e !== 'undefined') {
      warn(e);
    }
    return null;
  }
};
